<template>
  <responsive-layout>
    <v-sheet
      style="min-height: 100vh"
      class="d-flex flex-column justify-center transparent"
      light
    >
      <div class="main py-8">
        <v-container>
          <v-row
            justify="center"
            align="center"
            class="mx-4 mb-8 mb-lg-12 mt-5"
          >
            <v-col cols="12" class="d-flex flex-column align-center">
              <v-img
                class="mx-auto"
                src="https://vod.eventcdn.net/pandora/smc2021/Logo%402x.png"
                style="width: 385px; max-width: 100%"
              ></v-img>
            </v-col>

            <!--<v-col cols="12" style="max-width: 580px" class="mt-6">
              <div class="mx-auto">
                <p style="font-size: 1.1rem" class="white--text">
                  STARTING IN:
                </p>
                <br />
                <lh-countdown
                  :time="time"
                  value-class="countdown-value"
                  content-class="white--text mt-n10"
                  :labels="{
                    days: 'Days',
                    hours: 'Hours',
                    minutes: 'Minutes',
                    seconds: 'Seconds',
                  }"
                ></lh-countdown>
              </div>
            </v-col>-->
          </v-row>

          <!-- Form start -->
          <v-row align="center" justify="center" class="mx-4">
            <v-col cols="12" class="lighter-grey pa-0" style="max-width: 580px">
              <div
                class="pl-2 py-3 black text-left white--text"
                style="font-size: 1.2rem"
              >
                <h3 class="pa-4" v-if="!$vuetify.breakpoint.mobile">Login</h3>
                <h3 class="pa-4" v-else>Login</h3>
              </div>
              <div class="px-4 py-6">
                <validation-observer #default="{ invalid }">
                  <v-form @submit.prevent="login">
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <validation-provider
                            #default="{ errors }"
                            name="name"
                            rules="required|email"
                          >
                            <v-text-field
                              outlined
                              hide-details="auto"
                              v-model="credentials.username"
                              :error-messages="errors"
                              label="Email"
                              placeholder="Enter your email"
                              persistent-placeholder
                              type="email"
                              autocomplete="email"
                              required
                            >
                            </v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12">
                          <validation-provider
                            #default="{ errors }"
                            name="name"
                            rules="required"
                          >
                            <v-text-field
                              outlined
                              persistent-placeholder
                              v-model="credentials.password"
                              :error-messages="errors"
                              label="Password"
                              placeholder="Enter your password"
                              type="password"
                              required
                              autocomplete="current-password"
                              hide-details="auto"
                            >
                            </v-text-field>
                          </validation-provider>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="pt-0">
                          <validation-provider
                            #default="{ errors }"
                            name="livehouse terms & conditions"
                            rules="required"
                          >
                            <v-checkbox
                              v-model="accept_terms"
                              required
                              :false-value="null"
                              :error-messages="errors"
                            >
                              <template #label>
                                <div>
                                  I accept to have read and understood the NDA
                                  <br />
                                  <a
                                    class="black--text nda-link"
                                    style="font-size: 1rem"
                                    @click="toggleOverlay()"
                                    >Open NDA
                                  </a>
                                </div>
                              </template>
                            </v-checkbox>
                          </validation-provider>
                        </v-col>
                      </v-row>
                      <v-row justify="start" class="px-4">
                        <v-btn
                          :disabled="invalid"
                          color="pink"
                          class="
                            mr-4
                            black--text
                            font-weight-bold
                            elevation-0
                            rounded-0
                            login-btn
                          "
                          type="submit"
                          depressed
                          >Login</v-btn
                        >
                        <a
                          class="black--text password-link"
                          :href="resetPasswordUrl"
                          target="_blank"
                          rel="noopener noreferrer"
                          style="font-size: 1rem"
                          >Forgot your password?
                        </a>
                      </v-row>
                    </v-container>
                  </v-form>
                </validation-observer>
              </div>
            </v-col>
          </v-row>
        </v-container>

        <!-- Fullscreen overlay menu - start -->
        <v-overlay
          :z-index="zIndex"
          :value="overlay"
          opacity="1"
          class="menu-fullscreen-overlay nda-overlay-container"
        >
          <v-container fluid class="d-flex flex-column justify-center">
            <v-row>
              <v-col cols="12">
                <v-icon
                  large
                  dark
                  color="pink"
                  @click="toggleOverlay()"
                  class="fullscreen-overlay-close"
                >
                  fas fa-times-circle
                </v-icon>
              </v-col>
            </v-row>
            <v-row
              class="router-links-container d-flex align-center justify-center"
            >
              <v-card
                light
                class="
                  align-start
                  justify-start
                  d-flex
                  flex-column
                  elevation-0
                  rounded-0
                  nda-modal-card
                  text-left
                  pa-md-12 pa-6
                "
              >
                <v-col cols="12" class="nda-title mb-0 pl-0 pb-0">
                  <p class="mb-0">NDA - Important message!</p>
                </v-col>
                <v-col cols="12" class="nda-title mb-3 mt-0 pl-0">
                  <p>Pandora ME launch confidentiality</p>
                </v-col>

                <p class="nda-subheading">
                  With the much anticipated exciting launch of Pandora ME on
                  September 30th, you may begin to see information regarding the
                  launch, its products, and supporting marketing.
                </p>

                <v-card-text
                  class="mb-6 mt-3 white d-flex align-start justify-start"
                >
                  <v-icon left dark color="pink" large class="nda-icon">
                    fas fa-exclamation
                  </v-icon>
                  <span class="ml-5 exclamation-text">
                    With this, it is very important to note that all of the
                    information you may be exposed to related to Pandora ME is
                    strictly confidential and must not be disclosed to anyone
                    without permission until after September 30th, 2021.</span
                  >
                </v-card-text>

                <p class="nda-subheading">
                  We understand that with a launch of this magnitude and the
                  excitement we all feel around being able to share it with
                  other Pandorian’s and the outside world is hard to contain,
                  but we are asking that you keep the details of this launch
                  under wraps just a little while longer. We appreciate your
                  flexibility and cannot wait for the launch for the Pandora ME
                  collection.
                </p>

                <v-card-actions class="pl-0">
                  <v-btn
                    color="pink"
                    @click="toggleOverlay()"
                    class="nda-button rounded-0 elevation-0"
                  >
                    Back to login
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-row>
          </v-container>
        </v-overlay>

        <slot
          v-bind="{
            toggleOverlay,
          }"
        ></slot>
      </div>
    </v-sheet>
  </responsive-layout>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import ResponsiveLayout from "../layouts/ResponsiveLayout.vue";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ResponsiveLayout,
  },

  data() {
    return {
      credentials: {
        username: null,
        password: null,
      },
      error: false,
      time: "20210914T150000Z",
      zIndex: 99999999,
      overlay: false,

      accept_terms: null,
      resetPasswordUrl: `https://pandora-smc2021.portal.eventcdn.net/password/reset/`,
    };
  },

  computed: {
    ...mapGetters(["me", "isAuthenticated"]),
  },

  watch: {
    "$store.getters.isAuthenticated": async function (newValue, oldValue) {
      console.log("isAuthenticated change:", newValue, "oldValue:", oldValue);
      if (newValue) {
        await this.onLogin();
      }
    },
  },

  methods: {
    navigate() {
      const next = this.$route.query?.next;
      if (next) {
        this.$router.push(next);
      } else if (this.$store.getters.isAdmin) {
        this.$router.push({ name: "AdminHome" });
      } else {
        this.$router.push({ name: "Home" });
      }
    },
    async login() {
      this.error = false;
      try {
        await this.$store.dispatch("login", this.credentials);
      } catch (e) {
        this.error = true;
        console.debug("Login error:", e);
      }
    },
    async onLogin() {
      // Navigate
      this.navigate();
    },
    toggleOverlay() {
      this.overlay = !this.overlay;
    },
  },
};
</script>

<style scoped>
.main {
  background-image: url("https://vod.eventcdn.net/pandora/smc2021/Registration_Bg%402x.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /*height: 1080px;*/
  min-height: 100vh;
  backface-visibility: hidden;
}
/*
Needs to add padding to inner field
.v-input:not(.v-input--checkbox) {
  border: none !important;
  padding: 10px !important;
} */

.v-application.livehouse-app .countdown-value {
  font-size: 5rem !important;
  color: var(--v-primary-base) !important;
  line-height: 5rem !important;
  font-weight: 700 !important;
}

@media screen and (max-width: 992px) {
  .v-application.livehouse-app .countdown-value {
    font-size: 3rem !important;
    line-height: 3.5rem !important;
  }
}
</style>
